<template>
  <div class="detailsWrapper">
    <div class="btnWrapper">
      <el-button type="text" size="mini" @click="allAn">全部展开</el-button>
      <el-button type="text" size="mini" @click="packUp">全部收起</el-button>
    </div>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="停车场信息" name="1">
        <info-table
          :dataValue="detailList"
          :tableData="parkInfoValue"
          :num="3"
        ></info-table>
      </el-collapse-item>
      <el-collapse-item title="泊位信息" name="2">
        <info-table
          :dataValue="detailList"
          :tableData="berthInfoValue"
          :num="3"
        ></info-table>
      </el-collapse-item>
      <el-collapse-item title="出入口信息" name="3">
        <info-table
          :dataValue="detailList"
          :tableData="outInInfoValue"
          :num="3"
        ></info-table>
        <div v-for='(itemIn, index) in inInfoValue' :key="index">
          <info-table
          class="inTable"
          :tableData="itemIn"
          :num="4"
        ></info-table>
        </div>
        <div v-for='(itemOut, index) in outInfoValue' :key="index">
        <info-table
          class="outTable"
          :tableData="itemOut"
          :num="4"
        ></info-table>
        </div>
      </el-collapse-item>
      <el-collapse-item title="收费信息" name="4">
        <info-table
          :dataValue="detailList"
          :tableData="chargeInfoValue"
          :num="3"
        ></info-table>
        <infoTableA
          :dataValue="ruleData"
          :tableData="ruleInfo"
          :nameStyle="{ height: '150px', lineHeight: '150px' }"
          :valueStyle="{ height: '150px' }"
        >
          <!-- <template v-slot:rule>
            <el-input class="textArea" disabled type="textarea" autosize placeholder="请输入计费规则"
              v-model="ruleValue">
            </el-input>
          </template> -->
        </infoTableA>
      </el-collapse-item>
      <el-collapse-item title="员工信息" name="5">
        <info-table
          :style="{ width: '383px' }"
          :tableData="employeeInfo"
          :nameStyle="{ width: '190px' }"
          :valueStyle="{ width: '190px' }"
        ></info-table>
        <el-table
          :data="tableDataUser"
          border
          style="width: 100%!important,marignTop: 20px"
        >
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
        </el-table>
      </el-collapse-item>
      <el-collapse-item title="产权信息" name="6">
        <info-table
          :dataValue="detailList"
          :tableData="propertyInfoValue"
          :num="2"
        ></info-table>
      </el-collapse-item>
      <el-collapse-item
        title="上传资料（支持.jpg,.png,.pdf文件上传,每类材料不得超过10张）"
        name="7"
      >
        <div class="grid-content bg-purple">
          <p>工商营业执照</p>
          <!--<img src="./appoint1.png" alt="">-->
          <div v-for="item in fileList" :key="item.url" class="images">
            <img :src="item.url" />
          </div>
        </div>
        <div class="grid-content bg-purple">
          <p>土地使用权或产权证明</p>
          <div v-for="item in fileList1" :key="item.url" class="images">
            <img :src="item.url" />
          </div>
        </div>
        <div class="grid-content bg-purple">
          <p>泊位平面图或方位图</p>
          <div v-for="item in fileList2" :key="item.url" class="images">
            <img :src="item.url" />
          </div>
        </div>
        <div class="grid-content bg-purple">
          <p>竣工验收证明材料</p>
          <div v-for="item in fileList3" :key="item.url" class="images">
            <img :src="item.url" />
          </div>
        </div>
        <div class="grid-content bg-purple">
          <p>停车场照片</p>
          <div v-for="item in fileList4" :key="item.url" class="images">
            <img :src="item.url" />
          </div>
        </div>
        <div class="grid-content bg-purple">
          <p>其他</p>
          <div v-for="item in fileList5" :key="item.url" class="images">
            <img :src="item.url" />
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="流程记录" name="7">
        <el-table
          :data="parkFilingAuditData"
          border
          style="width: 100%!important,marignTop: 20px"
        >
          <el-table-column type="index" label="流程节点" width="150" align="center" :index="indexMethod"></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols1"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable/infoTableAfter";
import infoTableA from "@/components/infoTable/infoTable";
export default {
  name: "recordParkDetails",
  components: {
    infoTable,
    infoTableA,
  },
  // props: ['detailList'],
  data() {
    return {
      ruleData: {},
      textarea: "",
      parkInfoValue: [],
      berthInfoValue: [],
      outInInfoValue: [],
      chargeInfoValue: [],
      ruleValueInfo: [],
      propertyInfoValue: [],
      inInfoValue: [],
      outInfoValue: [],
      parkFilingAuditData: [],
      detailList: [],
      ruleValue: "",
      activeNames: ["1", "2", "3", "4", "5", "6", "7"],
      parkInfo: [
        {
          name: "停车场名称",
          key: "parkFilingName",
          required: true,
        },
        {
          name: "停车场编号",
          key: "parkFilingCode",
          required: true,
        },
        {
          name: "停车场类型",
          key: "parkFilingTypeName",
        },
        {
          name: "城市",
          key: "city",
        },
        {
          name: "所属行政区",
          key: "area",
          required: true,
        },
        {
          name: "所属街道",
          key: "street",
        },
        {
          name: "所属派出所",
          key: "policeName",
        },
        {
          name: "所属商圈",
          key: "businessCenterName",
        },
        {
          name: "所属交警分局",
          key: "trafficPoliceBranchName",
        },
        {
          name: "详细地址",
          key: "address",
          required: true,
        },
        {
          name: "停车场面积(㎡）",
          key: "parkFilingArea",
        },
        {
          name: "停车场四至范围",
          key: "fourToRange",
        },
        {
          name: "环路位置",
          key: "positionName",
        },
        {
          name: "停车设施用地",
          key: "facilitiesLandName",
        },
        {
            name: '占道类型',
            key: 'occupationTypeName'
        },
        {
            name: '停车方式',
            key: 'parkingMethodName'
        },
        {
          name: "停车场负责人",
          key: "personInCharge",
        },
        {
          name: "负责人联系方式",
          key: "personInChargePhone",
        },
        {
          name: "固定电话",
          key: "telephone",
        },
        {
          name: "停车场状态",
          key: "parkStatusName",
        },
        {
          name: "关联平台车场",
          key: "parkName",
        },
      ],
      berthInfo: [
        {
          name: "总泊位数",
          key: "totalBerthNumber",
          required: true,
        },
        {
          name: "专用泊位数",
          key: "dedicatedBerthNumber",
        },
        {
          name: "经营性泊位数",
          key: "operationalBerthNumber",
          required: true,
        },
        {
          name: "非经营性泊位数",
          key: "noOperationalBerthNumber",
        },
        {
          name: "规划泊位数",
          key: "planningBerthNumber",
        },
        {
          name: "共享泊位数",
          key: "sharedBerthNumber",
        },
        {
          name: "长租泊位数",
          key: "longLeaseBerthNumber",
        },
        {
          name: "电动汽车专用泊位数",
          key: "elecVehiclesBerthNumber",
        },
        {
          name: "无障碍泊位数",
          key: "barrierFreeBerthNumber",
        },
        {
          name: "大型车泊位数",
          key: "largeCarBerthNumber",
        },
        {
          name: "小型车泊位数",
          key: "littleCarBerthNumber",
        },
        {
          name: "地面泊位数",
          key: "groundBerthNumber",
        },
        {
          name: "地下泊位数",
          key: "undergroundBerthNumber",
        },
        {
          name: "楼层式泊位数",
          key: "floorTypeBerthNumber",
        },
        {
          name: "机械提升式泊位数",
          key: "mechanicalLiftBerthNumber",
        },
        {
          name: "地上机械式泊位数",
          key: "groundMechanicalBerthNumber",
        },
        {
          name: "地下机械式泊位数",
          key: "underGroundMechanicalBerthNumber",
        },
        {
          name: "自走式立体停车泊位数",
          key: "walkingStereoBerthNumber",
        },
        {
          name: "人防车位数",
          key: "airDefenseBerthNumber",
        },
      ],
      outInInfo: [
        {
          name: "出入口总数",
          key: "entryExitNumber",
        },
        {
          name: "入口数量",
          key: "entryNumber",
        },
        {
          name: "出口数量",
          key: "exitNumber",
        },
      ],
      inInfo: [
        {
          name: "入口1编号",
          key: "entryCode",
        },
        {
          name: "入口1经度",
          key: "entryLongitude",
        },
        {
          name: "入口1纬度",
          key: "entryLatitude",
        },
        {
          name: "入口1状态",
          key: "entryStatus",
        }
      ],
      outInfo: [
        {
          name: "出口1编号",
          key: "entryCode",
        },
        {
          name: "出口1经度",
          key: "entryLongitude",
        },
        {
          name: "出口1纬度",
          key: "entryLatitude",
        },
        {
          name: "出口1状态",
          key: "entryStatus",
        },
      ],
      chargeInfo: [
        {
          name: "营业时间",
          key: "businessTimeBegin",
        },
        {
          name: "首小时价格",
          key: "firstHourPrice",
        },
        {
          name: "非首小时每小时单价",
          key: "nofirstHourPrice",
        },
      ],
      ruleInfo: [
        {
          name: "计费规则描述",
          required: true,
          key: "feeDesc",
        },
      ],
      employeeInfo: [
        {
          name: "员工总数",
          key: "employeeNum"
        },
      ],
      tableDataUser: [
        {
          employeeId: "201020",
          employeeName: "蓝凤凰",
          sex: "0",
          phone: "19394020102",
          cardId: "102102102910291829",
          registPlace: "云南",
          employeeType: "1",
        },
        {
          employeeId: "201020",
          employeeName: "蓝凤凰",
          sex: "0",
          phone: "19394020102",
          cardId: "102102102910291829",
          registPlace: "云南",
          employeeType: "1",
        },
      ],
      tableCols: [
        {
          prop: "userCode",
          label: this.$t("list.Employee_ID"),
          width: "",
        },
        {
          prop: "userName",
          label: this.$t("list.pda_Manager_Name"),
          width: "",
        },
        {
          prop: "gender",
          label: "性别",
          width: "",
          formatter: (row) => {
            return row.gender == 0 ? "女" : "男";
          },
        },
        {
          prop: "phone",
          label: "联系方式",
          width: "",
        },
        {
          prop: "idCard",
          label: "身份证",
          width: "",
        },
        {
          prop: "domicile",
          label: "户籍",
          width: "",
        },
        {
          prop: "statusName",
          label: "员工状态",
          width: "",
          // formatter: (row) => {
          //   return row.employeeType == 0 ? "离职" : "在职";
          // },
        },
      ],
      tableCols1: [
        {
          prop: "operationType",
          label: this.$t("list.Operation_content"),
          width: "",
          formatter: (row) => {
            if (row.operationType == 1) {
              return '提交审核'
            } else if (row.operationType == 2) {
              return '审核'
            } else if (row.operationType == 3) {
              return '撤销审核'
            }
          },
        },
        {
          prop: "auditContent",
          label: this.$t("list.Review_comments"),
          width: "",
        },
        {
          prop: "description",
          label: "备注",
          width: "",
        },
        {
          prop: "creatorName",
          label: "办理人",
          width: "",
        },
        {
          prop: "createdTime",
          label: "办理时间",
          width: "",
        },
      ],
      propertyInfo: [
        {
          name: "经营企业",
          key: "operationName",
          required: true,
        },
        {
          name: "产权性质",
          key: "propertyRightsName",
        },
        {
          name: "产权方",
          key: "property",
        },
        {
          name: "停车场租赁有效期",
          key: "leaseValidity",
        },
      ],
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getDetailList2();
    // this.tableData = this.detailList.parkFilingUserList
    // let images = this.detailList.images
    // for (var i = 0; i < images.length; i++) {
    //   if (Number(images[i].type) >= 10 && Number(images[i].type) <= 19) {
    //     this.fileList.push(images[i])
    //   } else if (Number(images[i].type) >= 20 && Number(images[i].type) <= 29) {
    //     this.fileList1.push(images[i])
    //   } else if (images[i].type >= 30 && images[i].type <= 39) {
    //     this.fileList2.push(images[i])
    //   } else if (images[i].type >= 40 && images[i].type <= 49) {
    //     this.fileList3.push(images[i])
    //   } else if (images[i].type >= 50 && images[i].type <= 59) {
    //     this.fileList4.push(images[i])
    //   } else if (images[i].type >= 60 && images[i].type <= 69) {
    //     this.fileList5.push(images[i])
    //   }
    // }
    // console.log(this.detailList.parkFilingUserList, 'pppppp')
  },
  methods: {
    indexMethod(index) {
      return Math.abs(index - this.parkFilingAuditData.length)
    },
    allAn() {
      this.activeNames = ["1", "2", "3", "4", "5", "6", "7"];
    },
    packUp() {
      this.activeNames = [];
    },
    getDetailList2() {
      this.$axios
        .get("/acb/2.0/parkFiling/getUpdateById/" + this.$route.query.parkFilingId)
        .then((res) => {
          if (res.state == 0) {
            // 停车场信息 泊位信息 出入口信息第一行  收费信息 产权信息
            let businessTimeEnd = ''
            for (let itemInfo of res.value.parkFiling) {
              for (let itemChild of this.parkInfo) {
                if (itemInfo[itemChild.key]) {
                  itemChild.change = itemInfo.change;
                  itemChild.after = itemInfo.after;
                  if (itemChild.key == 'city' || itemChild.key == 'area' || itemChild.key == 'street') {
                      itemChild.valueInfo = itemInfo[itemChild.key].split("-")[1]
                  } else {
                      itemChild.valueInfo = itemInfo[itemChild.key]
                  }
               }
              }
              // 泊位信息
              for (let itemberthInfoChild of this.berthInfo) {
                if (itemInfo[itemberthInfoChild.key]) {
                  itemberthInfoChild.change = itemInfo.change;
                  itemberthInfoChild.after = itemInfo.after;
                  itemberthInfoChild.valueInfo =
                    itemInfo[itemberthInfoChild.key];
                }
              }
              // 出入口信息第一行
              for (let outInInfoChild of this.outInInfo) {
                if (itemInfo[outInInfoChild.key]) {
                  outInInfoChild.change = itemInfo.change;
                  outInInfoChild.after = itemInfo.after;
                  outInInfoChild.valueInfo = itemInfo[outInInfoChild.key];
                }
              }
              // 收费信息
              for (let chargeInfoChild of this.chargeInfo) {
                if (itemInfo[chargeInfoChild.key]) {
                  chargeInfoChild.change = 'N';
                  chargeInfoChild.after = itemInfo.after;
                  chargeInfoChild.valueInfo = itemInfo[chargeInfoChild.key];
                }
              }
              if (itemInfo.businessTimeEnd) {
                  businessTimeEnd =  itemInfo.businessTimeEnd
              }
              // 计费规则
              if (itemInfo[this.ruleInfo[0].key]) {
                this.ruleInfo[0].change = itemInfo.change;
                this.ruleInfo[0].after = itemInfo.after;
                this.ruleInfo[0].valueInfo = itemInfo[this.ruleInfo[0].key];
                this.ruleData.feeDesc = itemInfo[this.ruleInfo[0].key]
              }
              // 产权信息
              for (let propertyInfoChild of this.propertyInfo) {
                if (itemInfo[propertyInfoChild.key]) {
                  propertyInfoChild.change = itemInfo.change;
                  propertyInfoChild.after = itemInfo.after;
                  propertyInfoChild.valueInfo = itemInfo[propertyInfoChild.key];
                }
              }
            }
            if (this.chargeInfo[0].valueInfo) this.chargeInfo[0].valueInfo  = this.chargeInfo[0].valueInfo + "-" + businessTimeEnd
            // console.log("berthInfo", this.berthInfo);
            // 停车场信息 泊位信息 出入口信息第一行  收费信息 产权信息
            this.parkInfoValue = this.parkInfo;
            this.berthInfoValue = this.berthInfo;
            this.outInInfoValue = this.outInInfo;
            this.chargeInfoValue = this.chargeInfo;
            this.ruleValueInfo = this.ruleInfo;
            this.propertyInfoValue = this.propertyInfo;

            // 员工信息
            let parkFilingUserLsit = []
            for (let parkFilingUserItem of res.value.parkFilingUser) {
                  let userName = {
                    serialVersionUID: '',
                    parkFilingUserId: '',
                    parkFilingId: '',
                    userCode: '',
                    userName: '',
                    gender: '',
                    phone: '',
                    idCard: '',
                    domicile: '',
                    status: '',
                    genderName: '',
                    statusName: ''
                  }
                  for (let i of parkFilingUserItem) {
                      if (i.serialVersionUID) {
                        userName.serialVersionUID = i.serialVersionUID
                      }
                       if (i.parkFilingUserId) {
                        userName.parkFilingUserId = i.parkFilingUserId
                      }
                       if (i.parkFilingId) {
                        userName.parkFilingId = i.parkFilingId
                      }
                       if (i.userCode) {
                        userName.userCode = i.userCode
                      }
                       if (i.userName) {
                        userName.userName = i.userName
                      }
                       if (i.gender) {
                        userName.gender = i.gender
                      }
                       if (i.phone) {
                        userName.phone = i.phone
                      }
                       if (i.idCard) {
                        userName.idCard = i.idCard
                      }
                       if (i.domicile) {
                        userName.domicile = i.domicile
                      }
                      if (i.status) {
                        userName.status = i.status
                      }
                      if (i.genderName) {
                        userName.genderName = i.genderName
                      }
                      if (i.statusName) {
                        userName.statusName = i.statusName
                      }
                  }
                  parkFilingUserLsit.push(userName);
            }
            this.tableDataUser = parkFilingUserLsit;
            // 员工总数
            if (parkFilingUserLsit.length) {
                if (parkFilingUserLsit.length > 0) {
                  this.employeeInfo[0].valueInfo = parkFilingUserLsit.length
                }
            }

           let  inInfo = [
                          {
                            name: "入口1编号",
                            key: "entryCode",
                          },
                          {
                            name: "入口1经度",
                            key: "entryLongitude",
                          },
                          {
                            name: "入口1纬度",
                            key: "entryLatitude",
                          },
                          {
                            name: "入口1状态",
                            key: "entryStatus",
                          }
                        ]
            // 入口编号
            let enttyItemArr = [];
            if (res.value.parkFilingEntry.length) {
              for (let parkFilingItem of res.value.parkFilingEntry) {
                for (let inInfoItem of this.inInfo) {
                  for (let item of parkFilingItem) { 
                    if (item[inInfoItem.key] || item[inInfoItem.key] == 0) {
                      inInfoItem.change = item.change;
                      inInfoItem.after = item.after;
                      if (inInfoItem.key == "entryStatus") {
                        let entryStatusTag = item[inInfoItem.key] == 1 ? '开放' : '关闭'
                        inInfoItem.valueInfo = entryStatusTag
                      } else {
                        inInfoItem.valueInfo = item[inInfoItem.key];
                      }
                    }
                  }
                }
                let enttyItemArrI = []
                for (let item of this.inInfo) {                 
                        let objEnt = {}
                        objEnt.name = item.name
                        objEnt.key = item.key
                        objEnt.change = item.change
                        objEnt.after =  item.after
                        objEnt.valueInfo = item.valueInfo
                        enttyItemArrI.push(objEnt);  
                }
                enttyItemArr.push(enttyItemArrI)
              }              
            }
            // 出口编号
              let exitItemArr = []
              if (res.value.parkFilingExit.length) {
                for (let parkFilingItemOut of res.value.parkFilingExit) {
                // 出口
                for (let outInfoItem of this.outInfo) {
                    for (let item of parkFilingItemOut) {
                          if (item[outInfoItem.key] || item[outInfoItem.key] == 0) {
                          outInfoItem.change = item.change;
                          outInfoItem.after = item.after;
                          if (outInfoItem.key == 'entryStatus') {
                          let ExitStatusTag = item[outInfoItem.key] == 1 ? '开放' : '关闭'
                          outInfoItem.valueInfo = ExitStatusTag
                          } else {
                          outInfoItem.valueInfo = item[outInfoItem.key];
                        }
                      }
                    }
                }
                let extItemArrI = []
                for (let item of this.outInfo) {                 
                        let objExt = {}
                        objExt.name = item.name
                        objExt.key = item.key
                        objExt.change = item.change
                        objExt.after =  item.after
                        objExt.valueInfo = item.valueInfo
                        extItemArrI.push(objExt);  
                }
                exitItemArr.push(extItemArrI)
              }
              }
            console.log(exitItemArr);
            this.inInfoValue = enttyItemArr;
            this.outInfoValue = exitItemArr;
            // 流程记录
            this.parkFilingAuditData = res.value.parkFilingAudit;
            // 图片
            let images = res.value.parkFilingImages ? res.value.parkFilingImages : [];
            for (var i = 0; i < images.length; i++) {
              if (
                Number(images[i].type) >= 10 &&
                Number(images[i].type) <= 19
              ) {
                this.fileList.push(images[i]);
              } else if (
                Number(images[i].type) >= 20 &&
                Number(images[i].type) <= 29
              ) {
                this.fileList1.push(images[i]);
              } else if (images[i].type >= 30 && images[i].type <= 39) {
                this.fileList2.push(images[i]);
              } else if (images[i].type >= 40 && images[i].type <= 49) {
                this.fileList3.push(images[i]);
              } else if (images[i].type >= 50 && images[i].type <= 59) {
                this.fileList4.push(images[i]);
              } else if (images[i].type >= 60 && images[i].type <= 69) {
                this.fileList5.push(images[i]);
              }
            }
            // console.log("this.inInfo", this.inInfo);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.detailsWrapper {
  position: relative;
  padding 10px

  .inTable {
    margin-top: 20px;
  }

  .outTable {
    margin-top: 20px;
  }

  .textArea>>>textarea {
    height: 150px !important;
  }

  >>>.el-table {
    .el-table__header {
      height: 30px;
      line-height: 30px;
    }

    .el-table__body {
      height: 30px;
      line-height: 30px;
    }

    th, td {
      padding: 0px;
      border: 0px;
      border-right: 1px solid #EBEEF5;
      border-bottom: 1px solid #EBEEF5;
    }

    th {
      background: #f5f7fa;
    }
  }

  .grid-content {
    p {
      text-align: left;
    }

    img {
      margin-top: 10px;
      width: 200px;
      height 200px
    }
  }

  .btnWrapper {
    position: absolute;
    top: 10px;
    right: 50px;
  }
}

.images {
  border 1px solid #ddd
  margin-right 10px
  padding 0 10px
  display inline-block
}
</style>
