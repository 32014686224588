var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        _vm._l(_vm.tableData, function (val, ind) {
          return _c(
            "el-col",
            {
              key: ind,
              attrs: {
                span: !val.col ? 24 / _vm.num : (24 / _vm.num) * val.col,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "wrapperS",
                  class: {
                    "no-bottom": ind < _vm.tableData.length - _vm.num,
                    "no-right":
                      ind != _vm.tableData.length - 1 &&
                      (ind + 1) % _vm.num != 0,
                  },
                  style: _vm.wrapperStyle,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "colName",
                      style: _vm.getStyle(val),
                      attrs: { title: val.name },
                    },
                    [
                      val.required
                        ? _c("span", { staticClass: "required" })
                        : _vm._e(),
                      _vm._v(_vm._s(val.name) + " "),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "colValue",
                      style: _vm.handleStyle(val.style, _vm.valueStyle),
                      attrs: { title: val.value },
                    },
                    [
                      _vm._t(val.key || "default", function () {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  val.change == "Y"
                                    ? _c("div", [
                                        _vm._v("修改前：" + _vm._s(val.after)),
                                      ])
                                    : _vm._e(),
                                  val.change == "Y"
                                    ? _c("div", [
                                        _vm._v(
                                          "修改后：" +
                                            _vm._s(_vm.contentHandle(val))
                                        ),
                                      ])
                                    : _vm._e(),
                                  val.change == "N"
                                    ? _c("div", [
                                        _vm._v(_vm._s(_vm.contentHandle(val))),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", { staticClass: "htmlValue" }, [
                                _c("span", { style: _vm.getStyle(val) }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.contentHandle(val)) + " "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }