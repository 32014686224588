<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click="back" type="primary">返回</el-button>
      </div>
      <div v-if="flag == 1">
        <div class="shenhe" v-if="tag==2 || tag==3">
        <p class="sheheTile">审核说明:</p>
        <el-input
          type="textarea"
          :rows="2"
          maxlength="150"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>
        <el-button class="bottonS" @click="back" >取消</el-button>
        <el-button type="primary" class="bottonS"  @click="shNo">驳回</el-button>
        <el-button type="primary" class="bottonS"  @click="shYes">审核通过</el-button>
      </div>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="tabPosition" type="card" @tab-click="handleClick">
        <!-- <el-tab-pane label="概览" name="parent">
        </el-tab-pane> -->
        <el-tab-pane label="详细信息" name="child"> </el-tab-pane>
      </el-tabs>
      <!-- <overview v-if="tabPosition == 'parent'" :detailList="detailList"></overview> -->
      <details-info></details-info>
    </div>
  </div>
</template>
<script>
import detailsInfo from "./detailsInfoBA";
export default {
  name: "recordParkDetailsBA",
  data() {
    return {
      textarea: "",
      tabPosition: "parent",
      detailList: {},
      tag: false,
      parkFilingId: ''
    };
  },
  filters: {},
  methods: {
    // 返回
    back() {
      this.$router.go(-1);
    },
    handleClick(tab, event) {
      this.tabPosition = tab.name;
    },
    // 详情
    shNo() {
      this.$axios
        .post(
          "/acb/2.0/parkFiling/updateAuditStatus",
          {
            data: {
              auditStatus: 3,
              description: this.textarea,
              parkFilingId: this.parkFilingId
            }
          }
        )
        .then((res) => {
          if (res.state == 0) {
            // this.detailList = res.value;
            this.back()
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    shYes() {
      this.$axios
        .post(
          "/acb/2.0/parkFiling/updateAuditStatus",
          {
            data: {
              auditStatus: 4,
              description: this.textarea,
              parkFilingId: this.parkFilingId
            }
          }
        )
        .then((res) => {
          if (res.state == 0) {
            // this.detailList = res.value;
            this.back()
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {
    detailsInfo,
  },
  created() {
    console.log("this.$router", this.$route.query)
    this.tag = this.$route.query.auditStatus
    this.parkFilingId = this.$route.query.parkFilingId
    this.flag  = this.$route.query.flag
  },
  mounted() {
  },
  watch: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  // height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border-radius: 4px;
  padding: 20px 0;
}

.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}

.shenhe {
  height: 150px;
  background-color: #ffffff;
  font-family: "Helvetica Neue"
  padding-top: 10px;
}
.sheheTile{
  font-family: "Helvetica Neue"
  margin-bottom 20px
  margin-left 15px
}
.bottonS{
  float right
  margin-left 10px
}
</style>
